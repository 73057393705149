// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-connectors-index-tsx": () => import("./../../../src/pages/connectors/index.tsx" /* webpackChunkName: "component---src-pages-connectors-index-tsx" */),
  "component---src-pages-home-page-index-tsx": () => import("./../../../src/pages/homePage/index.tsx" /* webpackChunkName: "component---src-pages-home-page-index-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-payment-manager-oss-index-tsx": () => import("./../../../src/pages/payment-manager-oss/index.tsx" /* webpackChunkName: "component---src-pages-payment-manager-oss-index-tsx" */)
}

